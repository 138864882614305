import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodyLargeMedium, H5 } from '../../../components/typography'

import type { MspContract } from '../types/Contract'

type MspContractOverviewItemProps = {
  contract: MspContract
}

export const MspContractOverviewItem = ({
  contract,
}: MspContractOverviewItemProps) => {
  const { t } = useTranslation()

  return (
    <StContract>
      <StContractHeader>
        <StContractTitle>{contract.name}</StContractTitle>
        <BodyLargeMedium>
          {contract.members.length ?? 0} {t('employer.contracts.msp.employees')}
        </BodyLargeMedium>
      </StContractHeader>
    </StContract>
  )
}

const StContract = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`

const StContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  grid-column: span 2;
`

const StContractTitle = styled(H5)`
  margin: 0;
`
