import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { useKeenSlider } from 'keen-slider/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { styled, css } from 'styled-components'
import { match } from 'ts-pattern'

import { BodyLargeMedium, BodySmallRegular } from './typography'

import type { KeenSliderOptions, TrackDetails } from 'keen-slider/react'
import type { CSSProperties } from 'react'

// See https://codesandbox.io/s/github/rcbyr/keen-slider-sandboxes/tree/v6/ios-like-picker/date-time/react-typescript?file=/src/Wheel.tsx:666-1366
export function Wheel(props: WheelProps) {
  const perspective = props.perspective ?? 'center'
  const wheelSize = 20
  const slides = props.length
  const slideDegree = 360 / wheelSize
  const slidesPerView = props.loop ? 9 : 1
  const [sliderState, setSliderState] = useState<TrackDetails | null>(null)
  const size = useRef(0)

  const options = useRef<KeenSliderOptions>({
    slides: {
      number: slides,
      origin: props.loop ? 'center' : 'auto',
      perView: slidesPerView,
    },
    vertical: true,
    initial: props.initialIndex || 0,
    loop: props.loop,
    dragSpeed: (value) => {
      const height = size.current
      return (
        value *
        (height /
          ((height / 2) * Math.tan(slideDegree * (Math.PI / 180))) /
          slidesPerView)
      )
    },
    created: (slider) => {
      size.current = slider.size
    },
    updated: (slider) => {
      size.current = slider.size
    },
    detailsChanged: (s) => {
      setSliderState(s.track.details)
    },
    rubberband: !props.loop,
    mode: 'free-snap',
    slideChanged: async (slider) => {
      props.onChange?.(slider.track.details.rel)
      await Haptics.impact({ style: ImpactStyle.Light })
    },
  })

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(options.current)

  const [radius, setRadius] = useState(0)

  useEffect(() => {
    if (slider.current) {
      setRadius(slider.current.size / 2)
    }
  }, [slider])

  const slideValues = useMemo(() => {
    if (!sliderState) return []
    const offset = props.loop ? 1 / 2 - 1 / slidesPerView / 2 : 0

    const values = []
    for (let index = 0; index < slides; index++) {
      const distance = sliderState
        ? (sliderState.slides[index].distance - offset) * slidesPerView
        : 0
      const rotate =
        Math.abs(distance) > wheelSize / 2
          ? 180
          : distance * (360 / wheelSize) * -1
      const style: CSSProperties = {
        transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
        WebkitTransform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
      }
      const value = props.formatValue
        ? props.formatValue(index, sliderState.abs + Math.round(distance))
        : index
      values.push({ style, value, distance: Math.round(distance) })
    }
    return values
  }, [props, radius, sliderState, slides, slidesPerView])

  return (
    <StWheel
      ref={sliderRef}
      $perspective={perspective}
      className={props.className}
    >
      <StWheelShadowTop $radius={radius} />
      <StWheelInner>
        <StWheelSlides $width={props.width}>
          {slideValues.map(({ style, value, distance }, index) => (
            <StWheelSlide
              key={index}
              style={style}
              onClick={() => {
                slider.current?.moveToIdx(index)
              }}
            >
              <StBodyLargeMedium
                $selected={distance === 0}
                $distance={distance}
                $fontSize={
                  distance === 0
                    ? 18
                    : Math.max(10, 18 - Math.abs(distance) * 1.75)
                }
              >
                {value}
              </StBodyLargeMedium>
            </StWheelSlide>
          ))}
        </StWheelSlides>
        {props.label && (
          <StWheelLabel $radius={radius}>{props.label}</StWheelLabel>
        )}
      </StWheelInner>
      <StWheelShadowBottom $radius={radius} />
    </StWheel>
  )
}

type WheelPerspective = 'left' | 'right' | 'center'

type WheelProps = {
  initialIndex?: number
  label?: string
  length: number
  loop?: boolean
  perspective?: WheelPerspective
  formatValue?: (relative: number, absolute: number) => string
  width: number
  onChange?: (value: number) => void
  className?: string
}

const StWheel = styled.div<{
  $perspective: WheelPerspective
}>`
  display: block;
  height: 100%;
  overflow: visible;
  width: 100%;

  ${({ $perspective }) =>
    match($perspective)
      .with(
        'left',
        () => css`
          perspective-origin: calc(50% - 100px) 50%;
          transform: translateX(-10px);
        `
      )
      .with(
        'right',
        () => css`
          perspective-origin: calc(50% + 100px) 50%;
          transform: translateX(10px);
        `
      )
      .with('center', () => css``)
      .exhaustive()}
`

const StWheelInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  transform-style: preserve-3d;

  height: 30px;
  width: 100%;
`

const StWheelSlides = styled.div<{ $width: number }>`
  height: 100%;
  position: relative;
  width: ${({ $width }) => $width}px;
`

const StWheelSlide = styled.div`
  align-items: center;
  backface-visibility: hidden;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
`

const StWheelLabel = styled(BodySmallRegular)<{ $radius: number }>`
  margin-left: 5px;
  transform: ${({ $radius }) => `translateZ(${$radius}px)`};
`

const StWheelShadow = styled.div`
  left: 0;
  height: calc(42%);
  width: 100%;
  position: relative;
  z-index: 5;

  // Allow the onClick listener on the wheel item
  pointer-events: none;
`

const StWheelShadowTop = styled(StWheelShadow)<{ $radius: number }>`
  transform: ${({ $radius }) => `translateZ(${$radius}px)`};

  background: linear-gradient(
    to bottom,
    rgba(244, 246, 249, 0.75) 5%,
    rgba(244, 246, 249, 0.2) 100%
  );
`

const StWheelShadowBottom = styled(StWheelShadow)<{ $radius: number }>`
  transform: ${({ $radius }) => `translateZ(${$radius}px)`};
  background: linear-gradient(
    to bottom,
    rgba(244, 246, 249, 0.2) 5%,
    rgba(244, 246, 249, 0.75) 100%
  );
`

const StBodyLargeMedium = styled(BodyLargeMedium)<{
  $selected: boolean
  $fontSize: number
  $distance: number
}>`
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: ${({ theme, $selected }) =>
    $selected ? 'initial' : theme.theme.colors['nonary-5']};
  font-weight: ${({ $selected }) => ($selected ? 500 : 400)};
  transition-delay: ${({ $distance }) => $distance * 0.035}s;

  font-size: ${({ $fontSize }) => $fontSize}px;
`

export const SelectedWheelSlide = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 16%;
  background-color: ${({ theme }) => theme.theme.colors.white};
  border-radius: 4px;
`
