import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CountrySelectorDropdown,
  FlagImage,
  usePhoneInput,
} from 'react-international-phone'
import 'react-international-phone/style.css'
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { useUser } from '../../../user/hooks/useUser'
import { BottomSheet } from '../../bottom-sheet/BottomSheet'
import { Input, StHint, StLabel, StLabelContainer, StWarning } from '../Input'
import { StError } from '../Select'

import { englishCountries } from './data/countries-en'
import { frenchCountries } from './data/countries-fr'
import { dutchCountries } from './data/countries-nl'
import {
  StContainer,
  StCountriesDropdown,
  StCountrySelectorButton,
  StIcon,
  StPhoneInputContainer,
  StSelectionContainer,
} from './PhoneInput.styled'

import type {
  FocusEventHandler,
  HTMLInputAutoCompleteAttribute,
  InputHTMLAttributes,
  ReactNode,
} from 'react'
import type { CountryData, ParsedCountry } from 'react-international-phone'

type OurInputProps = {
  value: string
  onChange: (value: string) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  name: string
  label?: string
  placeholder?: string
  error?: string
  warning?: string
  touched?: boolean
  disabled?: boolean
  readOnly?: boolean
  type?: 'email' | 'text' | 'password' | 'tel' | 'number' | 'time'
  hint?: ReactNode
  count?: number | string
  autocomplete?: HTMLInputAutoCompleteAttribute
  size?: 'sm' | 'md' | 'lg'
  valueLabel?: string
}

export type PhoneInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  keyof OurInputProps
> &
  OurInputProps

export const PhoneInput = ({
  value,
  onChange,
  onBlur,
  name,
  label,
  error,
  warning,
  touched,
  disabled,
  readOnly,
  hint,
  size = 'md',
  ...rest
}: PhoneInputProps) => {
  const { user } = useUser()
  const language = user.language
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()

  const containerRef = useRef(null)
  useOnClickOutside(
    containerRef,
    () => isDesktop && setShowCountrySelector(false)
  )

  const [showCountrySelector, setShowCountrySelector] = useState(false)

  const { inputValue, country, setCountry, handlePhoneValueChange, inputRef } =
    usePhoneInput({
      defaultCountry: 'be',
      value: value === null ? '+32' : value,
      onChange: ({ phone }) => {
        onChange(phone)
      },
    })

  const { refs, floatingStyles } = useFloating({
    open: showCountrySelector,
    onOpenChange: setShowCountrySelector,
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ['top-start'],
      }),
    ],
    whileElementsMounted(referenceElement, floatingElement, update) {
      return autoUpdate(referenceElement, floatingElement, update, {
        animationFrame: true,
      })
    },
  })

  const handleSelectCountry = (country: ParsedCountry) => {
    setCountry(country.iso2)
    setShowCountrySelector(false)
    inputRef.current?.focus()
  }

  const filteredCountries: CountryData[] = useMemo(() => {
    return language === 'nl'
      ? dutchCountries
      : language === 'fr'
      ? frenchCountries
      : englishCountries
  }, [language])

  const countriesDropdown = (
    <StCountriesDropdown $show={showCountrySelector}>
      {filteredCountries.length > 0 ? (
        <CountrySelectorDropdown
          show={showCountrySelector}
          countries={filteredCountries}
          selectedCountry={country.iso2}
          onSelect={(props) => {
            handleSelectCountry(props)
          }}
          preferredCountries={['be', 'de', 'fr', 'lu', 'nl']}
        />
      ) : (
        <p>{t('onboarding.account.tel.no-countries')}</p>
      )}
    </StCountriesDropdown>
  )

  return (
    <StContainer $size={size}>
      {(label || (hint && !readOnly)) && (
        <StLabelContainer $readOnly={readOnly}>
          {label ? (
            <StLabel htmlFor={name} $readOnly={readOnly} $size={size}>
              {label}
            </StLabel>
          ) : null}
          {hint && !readOnly ? (
            typeof hint === 'string' ? (
              <StHint>{hint}</StHint>
            ) : (
              hint
            )
          ) : null}
        </StLabelContainer>
      )}

      <StPhoneInputContainer>
        {!readOnly && (
          <StSelectionContainer ref={containerRef}>
            <StCountrySelectorButton
              type="button"
              onClick={() =>
                !disabled && setShowCountrySelector(!showCountrySelector)
              }
              ref={refs.setReference}
              $active={showCountrySelector && isDesktop}
              $disabled={disabled}
            >
              <FlagImage iso2={country.iso2} size="24px" />
              <StIcon icon={['fass', 'triangle']} fontSize={8} />
            </StCountrySelectorButton>

            {isDesktop && (
              <div
                style={{ ...floatingStyles, zIndex: 1000 }}
                ref={refs.setFloating}
              >
                {countriesDropdown}
              </div>
            )}
          </StSelectionContainer>
        )}
        <Input
          value={inputValue}
          onChange={handlePhoneValueChange}
          onBlur={onBlur}
          name={name}
          touched={touched}
          error={error}
          hideErrorLabel
          disabled={disabled}
          warning={warning}
          ref={inputRef}
          readOnly={readOnly}
          {...rest}
        />
      </StPhoneInputContainer>

      {!isDesktop && (
        <BottomSheet
          isOpen={showCountrySelector}
          onClose={() => setShowCountrySelector(false)}
          hasBackdrop
          title={t('onboarding.account.tel.country-code')}
          snapPoints={[-34, 350]}
          initialSnap={1}
        >
          <StContainer $size={size}>{countriesDropdown}</StContainer>
        </BottomSheet>
      )}

      {touched && error ? <StError>{error}</StError> : null}
      {touched && warning && !error ? <StWarning>{warning}</StWarning> : null}
    </StContainer>
  )
}
