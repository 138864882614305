import { Capacitor } from '@capacitor/core'
import { Intercom as nativeIntercom } from '@capacitor-community/intercom'
import { useIntercom as useIntercomWeb } from 'react-use-intercom'

import { EventType, useTracking } from '../../analytics/hooks/useTracking'

import type { IntercomProps } from 'react-use-intercom'

export const useIntercom = () => {
  // -- Hooks --
  const webIntercom = useIntercomWeb()
  const { trackEvent } = useTracking()

  // -- Functions --
  const show = () => {
    trackEvent(EventType.Click, 'open_intercom')

    if (Capacitor.isNativePlatform()) {
      nativeIntercom.displayMessenger()
    } else {
      !webIntercom.isOpen && webIntercom.show()
    }
  }

  const boot = async (
    props: IntercomProps,
    userHashes: { web?: string; ios?: string; android?: string }
  ) => {
    if (Capacitor.isNativePlatform()) {
      nativeIntercom.setBottomPadding({ value: '40' })

      const platform = Capacitor.getPlatform()

      if (platform === 'android' && userHashes.android) {
        await nativeIntercom.setUserHash({
          hmac: userHashes.android,
        })
      }

      if (platform === 'ios' && userHashes.ios) {
        await nativeIntercom.setUserHash({
          hmac: userHashes.ios,
        })
      }

      // sleep a bit to avoid race condition
      await new Promise((r) => setTimeout(r, 200))

      await nativeIntercom.registerIdentifiedUser({
        userId: props.userId,
        email: props.email,
      })

      await nativeIntercom.updateUser({
        userId: props.userId,
        name: props.name,
        phone: props.phone,
        customAttributes: props.customAttributes,
      })

      // Web intercom
    } else {
      webIntercom.boot({ ...props, userHash: userHashes.web })
    }
  }

  const update = async (props: IntercomProps) => {
    if (Capacitor.isNativePlatform()) {
      await nativeIntercom.updateUser({
        userId: props.userId,
        name: props.name,
        phone: props.phone,
        customAttributes: props.customAttributes,
      })
    } else {
      webIntercom.update(props)
    }
  }

  const shutdown = () => {
    if (Capacitor.isNativePlatform()) {
      nativeIntercom.logout()
    } else {
      webIntercom.shutdown()
    }
  }

  const hide = () => {
    if (Capacitor.isNativePlatform()) {
      nativeIntercom.hideLauncher()
    } else {
      webIntercom.hide()
    }
  }

  return { show, boot, shutdown, hide, update }
}
