import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import {
  BodyExtraSmallRegular,
  BodyExtraSmallRegularCss,
  BodyMediumSemiBold,
  BodySmallSemiBold,
} from '../../../components/typography'
import { DetailLoader } from '../assets/DetailLoader'
import { chargingPointIsOpen } from '../lib/chargingPointIsOpen'

import { AddressNavigationCard } from './AddressNavigationCard'
import { ChargerAvailability } from './ChargerAvailability'
import { ChargerGroupCard } from './ChargerGroupCard'
import { OpeningHours } from './OpeningHours'

import type {
  ChargingLocation,
  OptimileEvsesChargingPoint,
  OptimileEvsesChargingPointResponse,
} from 'types'

type ChargeLocationDetailProps = {
  detailInfo: ChargingLocation
  data?: OptimileEvsesChargingPointResponse
  loading: boolean
  onClose: () => void
}

export const ChargeLocationDetail = ({
  detailInfo,
  data,
  loading,
  onClose,
}: ChargeLocationDetailProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Vars --

  const isOpen = useMemo(() => {
    return chargingPointIsOpen(detailInfo.opening_times)
  }, [detailInfo.opening_times])

  // -- Render --
  if (loading || !data) {
    return (
      <StLoaderContainer>
        <DetailLoader />
      </StLoaderContainer>
    )
  }

  // -- Vars --
  const chargerKwhGroups = data.results.reduce<
    Record<string, OptimileEvsesChargingPoint[]>
  >((group, chargingPoint) => {
    const { connector } = chargingPoint
    const power = Math.round(connector.power || 0).toString()
    group[power] = group[power] ?? []
    group[power].push(chargingPoint)

    return group
  }, {})

  const chargerGroups = data.results.reduce<
    Record<string, OptimileEvsesChargingPoint[]>
  >((group, chargingPoint) => {
    const { connector } = chargingPoint
    const power = Math.round(connector.power || 0).toString()
    const kwhPrice = connector.tariff.energy_components[0].price.toString()
    const hourPrice = connector.tariff.time_components[0].price.toString()
    const startingPrice = connector.tariff.starting_price.toString()

    const keyName = power + kwhPrice + hourPrice + startingPrice

    group[keyName] = group[keyName] ?? []
    group[keyName].push(chargingPoint)

    return group
  }, {})

  // -- Render --
  return (
    <StContainer>
      <StStickyContainer $isDesktop={isDesktop}>
        <div>
          {isDesktop && (
            <>
              <StTitle>{detailInfo.name}</StTitle>
              <StCloseButton onClick={onClose}>
                <FontAwesomeIcon icon={['fasr', 'xmark']} />
              </StCloseButton>
            </>
          )}
          <BodyExtraSmallRegular>
            {t(isOpen ? 'map.detail.open' : 'map.detail.closed')}
          </BodyExtraSmallRegular>
          <ChargerAvailability groups={Object.values(chargerKwhGroups)} />
        </div>
      </StStickyContainer>

      <StFlexContainer>
        <BodySmallSemiBold>{t('map.detail.opening-times')}</BodySmallSemiBold>
        <OpeningHours openingTimes={detailInfo.opening_times} />

        <BodySmallSemiBold>{t('map.detail.address')}</BodySmallSemiBold>
        <AddressNavigationCard detail={detailInfo} />
        <BodySmallSemiBold>
          {t('map.detail.prices')}
          <StInclVat>{t('map.detail.incl-vat')}</StInclVat>
        </BodySmallSemiBold>
        {Object.values(chargerGroups).map((group, index) => (
          <ChargerGroupCard key={index} group={group} />
        ))}
      </StFlexContainer>
    </StContainer>
  )
}

const StLoaderContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  display: flex;
  flex-direction: column;
`
const StTitle = styled(BodyMediumSemiBold)`
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StStickyContainer = styled.div<{ $isDesktop: boolean }>`
  padding-top: ${({ theme, $isDesktop }) =>
    $isDesktop ? theme.UI.SpacingPx.Space6 : 0};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.theme.colors.white};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StCloseButton = styled.button`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: transparent;
  border: none;

  cursor: pointer;

  color: ${({ theme }) => theme.theme.colors.black};
  opacity: 0.6;

  font-size: ${({ theme }) => theme.UI.SpacingPx.Space6};

  position: absolute;
  top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  right: -${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StInclVat = styled.span`
  ${BodyExtraSmallRegularCss}
  display: inline;
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space1};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
