import { Capacitor } from '@capacitor/core'
import { getHours } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { Urls } from '../../../api/urls'
import MapScreenshot from '../../../components/assets/map_screenshot.png'
import { IntercomCard } from '../../../components/intercom/IntercomCard'
import { BodySmallRegular, H4Css, H5Css } from '../../../components/typography'
import { onHover } from '../../../core/lib/styleHelpers'
import { DataTableProvider } from '../../../dataTable/providers/DataTableProvider'
import { HcpStatusType } from '../../../employer/employees/components/EmployeesDataTable'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import { HemsOnboardingCard } from '../../hems/onboarding/components/HemsOnboardingCard'
import { HemsProvider } from '../../hems/providers/HemsProvider'
import { RecentUsageDataList } from '../../usage/components/RecentUsageDataList'
import { StCard } from '../components/ChargingSessionCard'
import { DownloadAppCta } from '../components/DownloadAppCta'
import { EmployeeHomeHeader } from '../components/EmployeeHomeHeader'
import { HomeChargingSessionCard } from '../components/HomeChargingSessionCard'
import { HomeScreenElement } from '../components/HomeScreenElement'
import { StatusCard } from '../components/StatusCard'

export const EmployeeHomeScreen = () => {
  // -- Hooks --
  const { user } = useUser()
  const { currentChargeCard, currentMember } = useMember()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const userHasHcp = user.addresses.some(
    (address) => address.devices?.length > 0
  )
  const userIsApplicableForhems = [
    HcpStatusType.Configured,
    HcpStatusType.CertificationPlanned,
    HcpStatusType.Operational,
  ].includes(user.hcpStatus.status as HcpStatusType)
  const userHasHemsConfigured = !!user.addresses[0].scoptOnboarded
  const userHasScoptId = user.addresses[0].scoptId !== null

  // -- Memo's --
  const greeting = useMemo(() => {
    const currentHour = getHours(new Date())
    if (currentHour >= 6 && currentHour < 12) {
      return t('employee.home.good-morning', { userName: user.firstName })
    }
    if (currentHour >= 12 && currentHour < 18) {
      return t('employee.home.good-afternoon', { userName: user.firstName })
    }
    if (currentHour >= 18 && currentHour < 22) {
      return t('employee.home.good-evening', { userName: user.firstName })
    }
    return t('employee.home.good-night', { userName: user.firstName })
  }, [t, user.firstName])

  // -- Render --
  return (
    <>
      <EmployeeHomeHeader />
      <StPageHeader>
        <StPageHeaderContent>{greeting}</StPageHeaderContent>
      </StPageHeader>
      <StContainer>
        <StHomeContent>
          <StHomeContentColumn>
            {userHasHcp && userHasHemsConfigured && userHasScoptId ? (
              <HomeScreenElement
                title={t('employee.home.charging-session.title')}
              >
                <StLink
                  to={EmployeeRoutes.HEMS}
                  state={{ backTo: EmployeeRoutes.Root }}
                >
                  <HemsProvider>
                    <HomeChargingSessionCard member={currentMember} />
                  </HemsProvider>
                </StLink>
              </HomeScreenElement>
            ) : null}

            {userIsApplicableForhems && !userHasHemsConfigured && (
              <HemsOnboardingCard />
            )}

            <StatusCard hcpStatus={user.hcpStatus} />

            {currentChargeCard && !isDesktop && (
              <HomeScreenElement>
                <DataTableProvider
                  url={Urls.employeeRecentChargingSessions}
                  amount={1}
                >
                  <RecentUsageDataList />
                </DataTableProvider>
              </HomeScreenElement>
            )}

            {currentChargeCard && (
              <HomeScreenElement title={t('employee.home.search_charger')}>
                <StNavigationLink to={EmployeeRoutes.Map}>
                  <img src={MapScreenshot} alt="Map" />
                  <BodySmallRegular>
                    {t('employee.home.search_on_map')}
                  </BodySmallRegular>
                </StNavigationLink>
              </HomeScreenElement>
            )}

            {!Capacitor.isNativePlatform() && isDesktop && (
              <HomeScreenElement title={t('employee.home.download_the_app')}>
                <DownloadAppCta />
              </HomeScreenElement>
            )}
          </StHomeContentColumn>

          <StHomeContentColumn>
            {currentChargeCard && isDesktop && (
              <HomeScreenElement>
                <DataTableProvider
                  url={Urls.employeeRecentChargingSessions}
                  amount={3}
                >
                  <RecentUsageDataList />
                </DataTableProvider>
              </HomeScreenElement>
            )}

            <HomeScreenElement title={t('employee.home.assistant')}>
              <IntercomCard />
            </HomeScreenElement>
          </StHomeContentColumn>
        </StHomeContent>
      </StContainer>
    </>
  )
}

const StLink = styled(Link)`
  ${StCard}:hover {
    border: 1px solid ${({ theme }) => theme.theme.colors.black};
  }
`

const StContainer = styled.div`
  flex: 1;

  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  margin: 0 auto;

  @media ${breakpoints.desktop} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space11};
  }
`

const StPageHeader = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.theme.colors['primary-3']};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10} 0;
  }
`

const StPageHeaderContent = styled.div`
  ${H5Css}

  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 auto;
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  @media ${breakpoints.desktop} {
    ${H4Css}
    height: calc(var(--page-header-height));
  }
`

const StHomeContent = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  grid-gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    padding-top: 0;
    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.UI.SpacingPx.Space20};
  }
`

const StHomeContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StNavigationLink = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  align-items: center;

  color: ${({ theme }) => theme.theme.colors.black};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space3};

  ${onHover(
    css`
      border-color: ${({ theme }) => theme.theme.colors.black};
    `,
    false
  )}
`
