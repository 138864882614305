import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ZohoImage, ZohoModule } from '../image/ZohoImage'
import { BodyLargeMedium, BodyMediumRegular } from '../typography'

import { InformationOverviewType } from './InformationOverview'

export type InformationItem = {
  title: string
  content?: string | null
  images?: string[]
}

type InformationOverviewItemProps = {
  item: InformationItem
  type: InformationOverviewType
}

export const InformationOverviewItem = ({
  item,
  type = InformationOverviewType.KeyValue,
}: InformationOverviewItemProps) => {
  const { t } = useTranslation()

  return (
    <StOverviewItemContainer>
      {type === InformationOverviewType.KeyValue && (
        <>
          <BodyMediumRegular>{item.title}</BodyMediumRegular>
          <BodyLargeMedium>{item.content ?? '—'}</BodyLargeMedium>
        </>
      )}

      {type === InformationOverviewType.ZohoImages && (
        <>
          <BodyLargeMedium>{item.title}</BodyLargeMedium>
          <StImages>
            {item.images?.map((image) => (
              <StImage key={image}>
                <ZohoImage module={ZohoModule.File} id={image} />
              </StImage>
            ))}
            <StPhotoCount>
              {t('onboarding.overview.photo_count', {
                count: item.images?.length ?? 0,
              })}
            </StPhotoCount>
          </StImages>
        </>
      )}
    </StOverviewItemContainer>
  )
}

const StOverviewItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StImage = styled.div`
  width: ${({ theme }) => theme.UI.SpacingPx.Space15};
  height: ${({ theme }) => theme.UI.SpacingPx.Space15};
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  overflow: hidden;
`

const StImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  flex-wrap: wrap;
`

const StPhotoCount = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-dark']};
`
