import { format } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { minutesToHoursAndMinutes } from '../../../../../../../packages/utils/src/time/minutesToHoursAndMinutes'
import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonQuinary } from '../../../components/button/ButtonQuinary'
import {
  BodyExtraSmallRegular,
  BodyLargeSemiBold,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { DataList } from '../../../dataTable/components/DataList'
import { ColumnType } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { valueToDate } from '../../../translations/utils/date'

import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSessionWithMsp } from 'types'

export const RecentUsageDataList = () => {
  const { t } = useTranslation()
  const dateFnsLocale = useGetDateFnsLocale()
  const { amount, loading, dataCount } = useDataTable()
  const navigate = useNavigate()
  const { locale } = useGetDateFnsLocale()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const columns = useMemo<Column<ChargingSessionWithMsp>[]>(
    () => [
      {
        key: 'start',
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithMsp) => {
          return format(new Date(data.start), 'dd MMMM', { locale })
        },
        component: StDate,
      },
      {
        key: 'spacing',
        type: ColumnType.STRING,
        transformData: () => {
          return ' '
        },
      },
      {
        key: 'place',
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithMsp) => {
          return data.chargingPointId
            ? t('employee.chargingSessions.at-home')
            : t('employee.chargingSessions.public')
        },
      },
      {
        key: 'duration',
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithMsp) => {
          const duration = minutesToHoursAndMinutes(data.duration)

          return (
            <>
              {duration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: duration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: duration.minutes,
              })}
            </>
          )
        },
      },
    ],
    [t]
  )

  const desktopColumns = useMemo<Column<ChargingSessionWithMsp>[]>(
    () => [
      {
        key: 'place',
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithMsp) => {
          return data.chargingPointId
            ? t('employee.chargingSessions.at-home')
            : t('employee.chargingSessions.public')
        },
      },
      {
        key: 'duration',
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithMsp) => {
          const duration = minutesToHoursAndMinutes(data.duration)

          return (
            <>
              {duration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: duration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: duration.minutes,
              })}
            </>
          )
        },
      },
    ],
    [t]
  )

  const groupOn = useMemo<Column<ChargingSessionWithMsp>>(() => {
    return {
      key: 'start',
      type: ColumnType.STRING,
      transformData: (data: ChargingSessionWithMsp) => {
        return format(valueToDate(data.start), 'd MMMM', dateFnsLocale)
      },
    }
  }, [dateFnsLocale])

  const handleRowClick = useCallback(
    (dataRow: ChargingSessionWithMsp) => {
      navigate(EmployeeRoutes.Usage, {
        state: {
          usageType: dataRow.chargingPointId ? 'hcp' : 'msp',
        },
      })
    },
    [navigate]
  )

  return (
    <>
      <StTitleRow>
        <BodyLargeSemiBold>
          {t('employee.home.recent_charging_sessions', { count: amount })}
        </BodyLargeSemiBold>
        {!loading && dataCount.itemCount > 0 && (
          <ButtonQuinary
            onClick={() => {
              navigate(EmployeeRoutes.Usage)
            }}
          >
            {t('employee.home.show_all')}
          </ButtonQuinary>
        )}
      </StTitleRow>
      <DataList<ChargingSessionWithMsp>
        columns={isDesktop ? desktopColumns : columns}
        groupOn={isDesktop ? groupOn : undefined}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.chargingSessions.list.empty-title')}
        emptyFiltersTitle=""
      />
    </>
  )
}

const StTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StDate = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
