import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  forwardRef,
  useRef,
  useState,
  type ChangeEvent,
  type FocusEventHandler,
  type HTMLInputAutoCompleteAttribute,
  type InputHTMLAttributes,
  type ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useResizeObserver } from 'usehooks-ts'

import {
  BodyLargeMediumCss,
  BodyLargeRegularCss,
  BodyLargeSemiBoldCss,
  BodyMediumMedium,
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodyMediumSemiBoldCss,
  BodySmallRegularCss,
  BodySmallSemiBoldCss,
} from '../typography'

type OurInputProps = {
  value: string | number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  name: string
  label?: string
  placeholder?: string
  error?: string
  warning?: string
  touched?: boolean
  disabled?: boolean
  readOnly?: boolean
  type?: 'email' | 'text' | 'password' | 'tel' | 'number' | 'time'
  hint?: ReactNode
  count?: number | string
  autocomplete?: HTMLInputAutoCompleteAttribute
  size?: 'sm' | 'md' | 'lg'
  valueLabel?: string
  hideErrorLabel?: boolean
}

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  keyof OurInputProps
> &
  OurInputProps

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    value,
    onChange,
    onBlur,
    name,
    label,
    placeholder,
    error,
    warning,
    touched,
    disabled,
    readOnly,
    type = 'text',
    hint,
    count,
    autocomplete,
    size = 'md',
    valueLabel,
    hideErrorLabel,
    ...rest
  }: InputProps,
  ref
) {
  const { t } = useTranslation()
  const widthMeasurerRef = useRef<HTMLSpanElement | null>(null)
  const { width } = useResizeObserver({ ref: widthMeasurerRef })
  const [innerType, setInnerType] = useState<
    'email' | 'text' | 'password' | 'tel' | 'number' | 'time'
  >(type)

  return (
    <StContainer>
      {(label || (hint && !readOnly)) && (
        <StLabelWrapper>
          <StLabelContainer $readOnly={readOnly}>
            {label ? (
              <StLabel htmlFor={name} $readOnly={readOnly} $size={size}>
                {label}
              </StLabel>
            ) : null}
            {hint && !readOnly ? (
              typeof hint === 'string' ? (
                <StHint>{hint}</StHint>
              ) : (
                hint
              )
            ) : null}
          </StLabelContainer>
          {type === 'password' && !readOnly && (
            <StPasswordViewer
              onClick={() =>
                setInnerType(innerType === 'password' ? 'text' : 'password')
              }
            >
              <FontAwesomeIcon
                fontSize={16}
                icon={['fasr', innerType === 'password' ? 'eye' : 'eye-slash']}
              />
              <BodyMediumMedium>
                {innerType === 'password'
                  ? t('input.password.show')
                  : t('input.password.hide')}
              </BodyMediumMedium>
            </StPasswordViewer>
          )}
        </StLabelWrapper>
      )}
      <StInputContainer
        $error={touched && !!error}
        $touched={touched}
        $disabled={disabled || readOnly}
        $readOnly={readOnly}
        $size={size}
      >
        <WidthMeasurer ref={widthMeasurerRef} $readOnly={readOnly} $size={size}>
          {value}
        </WidthMeasurer>
        <StInput
          ref={ref}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled || readOnly}
          onBlur={onBlur}
          type={readOnly ? 'text' : innerType}
          $readOnly={readOnly}
          $size={size}
          $width={width}
          autoComplete={autocomplete}
          data-lpignore={readOnly ? 'true' : undefined}
          data-1p-ignore={readOnly ? 'true' : undefined}
          {...rest}
        />
        {valueLabel && (
          <StValueLabel $readOnly={readOnly} $size={size}>
            {valueLabel}
          </StValueLabel>
        )}
        {touched && !readOnly && !disabled && (
          <StStatusIcon
            icon={[
              'fasr',
              error
                ? 'triangle-exclamation'
                : warning
                ? 'info-circle'
                : 'check',
            ]}
            $error={!!error}
            $warning={!!warning}
          />
        )}
        {count && <StCount>{count}</StCount>}
      </StInputContainer>
      {touched && error && !hideErrorLabel ? <StError>{error}</StError> : null}
      {touched && warning && !error ? <StWarning>{warning}</StWarning> : null}
    </StContainer>
  )
})

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StLabel = styled.label<{
  $readOnly?: boolean
  $size: 'sm' | 'md' | 'lg'
}>`
  ${({ $readOnly, $size }) =>
    $readOnly
      ? BodyMediumRegularCss
      : $size === 'sm'
      ? BodySmallSemiBoldCss
      : $size === 'lg'
      ? BodyLargeSemiBoldCss
      : BodyMediumSemiBoldCss}
  color: ${({ theme, $readOnly }) =>
    $readOnly
      ? theme.theme.text.body['gray-mid']
      : theme.theme.text.body.black};
  display: block;
`

export const StLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StLabelContainer = styled.div<{ $readOnly?: boolean }>`
  display: flex;
  flex-grow: 1;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: center;
  margin-bottom: ${({ theme, $readOnly }) =>
    $readOnly ? 0 : theme.UI.SpacingPx.Space2};
`

export const StPasswordViewer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
  align-items: center;

  color: ${({ theme }) => theme.theme.colors['nonary-2']};
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
`

export const StHint = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.components.input['info-text']};
`

const StInputContainer = styled.div<{
  $error?: boolean
  $touched?: boolean
  $disabled?: boolean
  $readOnly?: boolean
  $size: 'sm' | 'md' | 'lg'
}>`
  position: relative;

  height: ${({ $size }) =>
    $size === 'sm' ? '44px' : $size === 'lg' ? '60px' : '52px'};
  display: flex;

  border: ${({ $disabled }) => ($disabled ? 'none' : '1px solid')};
  border-color: ${({ theme, $error, $touched, $readOnly }) =>
    $readOnly
      ? 'none'
      : $error
      ? theme.components.input.error
      : $touched
      ? theme.components.input.success
      : theme.components.input.border};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme, $disabled, $readOnly }) =>
    $readOnly
      ? 'transparent'
      : $disabled
      ? theme.components.input['disabled-bg']
      : theme.components.input.bg};
  overflow: hidden;

  &:focus-within {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &:hover {
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }
`

const StInput = styled.input<{
  $readOnly?: boolean
  $width?: number
  $size: 'sm' | 'md' | 'lg'
}>`
  ${({ $readOnly, $size }) =>
    $readOnly
      ? BodyLargeMediumCss
      : $size === 'sm'
      ? BodySmallRegularCss
      : $size === 'lg'
      ? BodyLargeRegularCss
      : BodyMediumRegularCss}

  height: 100%;
  width: ${({ $readOnly, $width, value }) =>
    $readOnly ? (value ? `${$width ? $width + 4 : 0}px` : 'auto') : '100%'};

  padding: 0
    ${({ theme, $readOnly }) => ($readOnly ? 0 : theme.UI.SpacingPx.Space4)};
  padding-right: ${({ theme, $readOnly }) =>
    $readOnly ? 0 : theme.UI.SpacingPx.Space12};
  border: none;

  &:disabled {
    color: ${({ theme, $readOnly }) =>
      $readOnly
        ? theme.components.input['input-text']
        : theme.components.input['disabled-text']};
    background-color: unset;
  }

  &::placeholder {
    color: ${({ theme }) => theme.components.input['placeholder-text']};
  }

  &:focus {
    outline: none;
  }
`

const StStatusIcon = styled(FontAwesomeIcon)<{
  $error?: boolean
  $warning?: boolean
}>`
  position: absolute;
  right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  top: 50%;
  transform: translateY(-50%);

  margin: auto 0;
  color: ${({ $error, theme, $warning }) =>
    $error
      ? theme.components.input.error
      : $warning
      ? theme.theme.colors['nonary-3']
      : theme.components.input.success};
`

const StCount = styled.span`
  ${BodyMediumSemiBoldCss}
  background-color: ${({ theme }) => theme.theme.colors['primary-0']};
  border-radius: 999px;
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};

  margin: auto 0;
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};

  height: ${({ theme }) => theme.UI.SpacingPx.Space9};
  min-width: ${({ theme }) => theme.UI.SpacingPx.Space9};

  display: grid;
  place-items: center;
`

export const StError = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  color: ${({ theme }) => theme.components.input.error};
`

export const StWarning = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  color: ${({ theme }) => theme.theme.colors['nonary-3']};
`

const StValueLabel = styled.span<{
  $size: 'sm' | 'md' | 'lg'
  $readOnly?: boolean
}>`
  ${({ $readOnly, $size }) =>
    $readOnly
      ? BodyLargeMediumCss
      : $size === 'sm'
      ? BodySmallRegularCss
      : $size === 'lg'
      ? BodyLargeRegularCss
      : BodyMediumRegularCss}

  color: ${({ theme }) => theme.components.input['label-text']};
  margin: auto 0;
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  white-space: nowrap;
`

const WidthMeasurer = styled.span<{
  $size: 'sm' | 'md' | 'lg'
  $readOnly?: boolean
}>`
  ${({ $readOnly, $size }) =>
    $readOnly
      ? BodyLargeMediumCss
      : $size === 'sm'
      ? BodySmallRegularCss
      : $size === 'lg'
      ? BodyLargeRegularCss
      : BodyMediumRegularCss}

  position: absolute;
  visibility: hidden;
`
