import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import {
  BodyMediumSemiBold,
  BodySmallRegular,
  H4,
} from '../../../../components/typography'
import { useIntercom } from '../../../../intercom/hooks/useIntercom'
import { useUser } from '../../../../user/hooks/useUser'
import dongle from '../assets/dongle-front.png'
import { useHemsFlow } from '../hooks/useHemsFlow'

enum ProvisioningState {
  CONNECTING = 'CONNECTING',
  ERROR = 'ERROR',
}

export const UtpProvisioningStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { macAddress, flowNavigation } = useHemsFlow()
  const { show } = useIntercom()
  const { refetch } = useUser()

  // -- State --
  const [provisioningState, setProvisioningState] = useState<ProvisioningState>(
    ProvisioningState.ERROR
  )

  // -- Data --
  const [, provision] = useAuthMemberAxios(
    {
      url: Urls.connectHems,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleProvision = async () => {
    setProvisioningState(ProvisioningState.CONNECTING)

    try {
      const { status } = await provision({
        data: {
          macAddress,
          connectionMethod: 'Utp',
          // For now, safeInstall is not working
          safeInstall: false,
        },
      })

      if (status === 201) {
        await refetch()
        flowNavigation.next()
      } else {
        setProvisioningState(ProvisioningState.ERROR)
      }
    } catch {
      setProvisioningState(ProvisioningState.ERROR)
    }
  }

  // -- Effects --
  useEffect(() => {
    handleProvision()
  }, [])

  // -- Render --
  return (
    <>
      <StTextContainer>
        <H4>
          {t(
            `employee.hems.dongle-onboarding.utp-provisioning.${provisioningState}.title`
          )}
        </H4>
        <BodySmallRegular>
          {t(
            `employee.hems.dongle-onboarding.utp-provisioning.${provisioningState}.description`
          )}
        </BodySmallRegular>
      </StTextContainer>

      <StImage src={dongle} alt="dongle" />

      {provisioningState === ProvisioningState.ERROR && (
        <StIconContainer $error>
          <FontAwesomeIcon icon={['fasr', 'xmark']} />
        </StIconContainer>
      )}

      <FloatingButtonContainer>
        {provisioningState === ProvisioningState.ERROR && (
          <>
            <ButtonTertiary style={{ marginBottom: '12px' }} onClick={show}>
              {t(
                'employee.hems.dongle-onboarding.utp-provisioning.ERROR.help-button'
              )}
            </ButtonTertiary>
            <ButtonPrimary onClick={handleProvision}>
              {t(
                'employee.hems.dongle-onboarding.utp-provisioning.ERROR.try-again-button'
              )}
            </ButtonPrimary>
          </>
        )}

        {provisioningState === ProvisioningState.CONNECTING && (
          <StSpinnerContainer>
            <StSpinner />
            <BodyMediumSemiBold>
              {t('employee.hems.dongle-onboarding.connecting.loading')}
            </BodyMediumSemiBold>
          </StSpinnerContainer>
        )}
      </FloatingButtonContainer>
    </>
  )
}

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StImage = styled.img`
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -1;

  width: 100%;
  max-height: 50%;

  object-fit: contain;
`

const StSpinner = styled.span`
  width: 18px;
  height: 18px;
  border: 2.5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StIconContainer = styled.div<{ $error?: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${({ theme, $error }) =>
    $error ? theme.components.input.error : theme.theme.colors['primary-1']};
  color: ${({ theme, $error }) =>
    $error ? theme.theme.colors.white : theme.theme.colors.black};
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space8};

  height: 70px;
  width: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
`
